<template>
  <ComplianceCategoryGrid />
</template>

<script>
import ComplianceCategoryGrid from '../../components/ComplianceCategoryGrid.vue';

export default {
  name: "ComplianceGrid",
  components: {
    ComplianceCategoryGrid: ComplianceCategoryGrid,
  },
  created() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>
